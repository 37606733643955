.minWidth-sidebar {
  width: 80px !important;
}
.site-layout-background {
  /* padding: 24px; */
  /* min-height: calc(100vh - 130px); */
  min-height: calc(100vh - 58px);
}
.new-app-layout .updated-sidebar .sign-out-menu {
  box-shadow: 0px 0px 6px 0px #00000040;
  border: none;
  min-width: 19rem !important;
}
.new-app-layout .updated-sidebar .btn-hi {
  border-radius: 50px !important;
  color: black;
  background-color: #79cef2;
  font-size: 13px !important;
  width: 35px;
  height: 35px;
  font-weight: 600;
  text-align: center;
  padding: 5px 6px;
  border: 2px solid white;
  display: flex;
}
.user-profile-out {
  color: #60c3ff;
}
.child-custom-layout .btn-edit {
  background-color: #0494cc;
  padding: 5px 30px;
}
.new-app-layout .updated-sidebar ul li {
  cursor: pointer;
}
.new-app-header .nav-link {
  color: rgba(0, 0, 0, 0.5);
  border-right: 2px solid #d4d4d4;
  line-height: 5px;
}
.profile-img {
  border-radius: 50%;
}
/* .new-app-header .active {
    border-bottom: 3px solid rgb(6, 132, 222);
  } */
.user-name {
  font-weight: 700;
}
.user-job {
  color: #0494cc;
}
.new-app-header .navbar-brand {
  font-size: 16px;
}
.new-app-header .nav-link {
  padding-right: 25px !important;
  padding-left: 25px !important;
}
.child-custom-layout {
  /* background-color: #e5e5e5; */
  background-color: #f1f5f9;
  margin-top: 57px;
}
.new-app-header {
  background-color: rgb(255, 255, 255) !important;
  position: fixed;
  width: 95.3%;
  z-index: 2;
  margin-left: 75px;
}
.new-app-header .title-resources {
  background-color: white !important;
  font-weight: 700;
}
.new-app-layout .updated-sidebar {
  background-color: white !important;
  /* border-top-right-radius: 25px; */
  position: fixed;
  z-index: 1;
  height: 100%;
}
.new-generic-color {
  border: none;
  background-color: #2094cd;
  color: white;
}
.new-generic-color:hover {
  box-shadow: 0px 1px 7px 0px rgb(0 0 0 / 50%);
  background-color: #2094cd;
  color: white !important;
}
.generic-color-text {
  color: #2094cd !important;
}
/* .plan-your-day{
    border: 2px solid #2094cd !important;
    background-color: none !important;
  } */
.generic-heading-level-3 {
  font-weight: 600;
  font-size: 15px;
  color: #1c1d21 !important;
  line-height: 18px;
  font-family: inter !important;
}
.Task-Description {
  height: 90px !important;
  line-height: 14px;
}
.generic-heading-level-2 {
  font-weight: 700;
  font-size: 17px !important;
}
.Welcome-txt {
  color: #5e81f4;
  font-size: 26px;
  font-weight: 600;
  font-family: "Inter";
  line-height: 28px;
}
.plan-day {
  background-color: white !important;
  border: 2px solid #5e81f4 !important;
  color: #5e81f4 !important;
  font-weight: 500;
  font-family: "Inter";
}
.specific-color {
  color: #8080806e;
}
.Have-nice-text {
  color: #666666 !important;
  font-size: 21px;
  font-family: "Inter";
}
/* .sticky-height{
    height:calc(100vh - 227px) !important;
    overflow: auto;
  } */

.home-cards-height {
  margin-top: 28px;
}

.attendence-height {
  height: calc(100vh - 227px) !important;
  overflow: auto;
}
.status-td-set {
  background-color: #ecfbe8;
  padding: 4px 12px;
  border-radius: 50px;
  color: #39c760;
  font-size: 12px;
  font-family: "Inter";
  font-weight: 500;
}
.workLoad-content {
  height: 20rem !important;
}
.check-box-generic {
  color: #666666;
  font-size: 14px;
  font-weight: 400;
}
.status-td-set-red {
  background-color: #ffd8d8 !important;
  padding: 4px 12px;
  border-radius: 50px;
  color: #e54545;
  font-size: 11px;
  font-family: "Inter";
  font-weight: 500;
}
.status-td-set-reds {
  background-color: #e6e8f0 !important;
  padding: 4px 12px;
  border-radius: 50px;
  color: #666666;
  font-family: "Inter";
  font-weight: 500;
  font-size: 11px;
}
.List-btn {
  padding: 8px 15px;
  background-color: #dbe3ef;
  color: #5e81f4;
  border-radius: 3px;
  font-size: 14px;
  height: 38px;
  margin-top: 8px;
}
.last-text-clear {
  color: #b5b2b2 !important;
  font-size: 13px !important;
}
.grid-button {
  text-transform: uppercase;
  color: white;
  text-decoration: none;
  border: none;
  font-size: 13px;
  outline: none;
  height: 38px;
  transition: 0.3s;
  padding: 8px 15px !important;
  background-color: #4c89dc !important;
  margin-top: 8px;
}
.icon-color-list {
  color: #5e81f4;
}

.zoom-metting-logo {
  background-color: white;
  border-radius: 46px;
  width: 26px;
  height: 26px;
  position: relative;
  font-size: 18px;
}
.vedio-Body {
  background-color: black;
}
.vedioCall-end {
  background-color: #e54545 !important;
  border: none;
  font-size: 10px;
  padding: 4px 11px 2px 11px;
}

.signUp-conatainer {
  height: 100vh !important;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.meeting_table-height {
  height: calc(100vh - 228px) !important;
}
.Home-Table thead th {
  border: none !important;
  font-size: 12px !important;
  color: black !important;
  text-transform: capitalize !important;
  background-color: #fafbff !important;
}
.card-bg-color {
  background-color: #ffe278 !important;
  border-radius: 17px;
  padding: 14px !important;
}
.card-paragraph {
  font-size: 12px !important;
  font-weight: 600;
}
.card-edit-background {
  font-size: 21px;
  color: white;
  background-color: black;
  padding: 4px;
  border-radius: 4px;
}
.date-sticky {
  color: gray;
}
.card-trash-background {
  font-size: 21px;
  color: rgb(212, 19, 19);
  background-color: rgb(243, 237, 237);
  padding: 4px;
  border-radius: 4px;
}
.Home-Table tbody tr td {
  font-size: 12px !important;
  color: #727274 !important;
  border: none;
}
.dashboard-modal {
  vertical-align: middle !important;
}

tbody tr .table-edit-icon {
  font-size: 14px !important;
}
.sticky-card {
  border-radius: 18px !important;
}

.stiky-Colors-open {
  height: 48px !important;
}
.stiky-color1 {
  background-color: #ffe66e;
  width: 14.28%;
}
.stiky-bg-modal1 {
  background-color: #ffe66e;
}
.stiky-color2 {
  background-color: #a1ef9b;
  width: 14.28%;
}
.stiky-bg-modal2 {
  background-color: #a1ef9b;
}

.stiky-color3 {
  background-color: #ffafdf;
  width: 14.28%;
}

.stiky-bg-modal3 {
  background-color: #ffafdf;
}
.stiky-color4 {
  background-color: #d7afff;
  width: 14.28%;
}
.stiky-bg-modal4 {
  background-color: #d7afff;
}
.stiky-color5 {
  background-color: #9edfff;
  width: 14.28%;
}

.stiky-bg-modal5 {
  background-color: #9edfff;
}
.stiky-color6 {
  background-color: #e0e0e0;
  width: 14.28%;
}
.stiky-bg-modal6 {
  background-color: #e0e0e0;
}
.stiky-color7 {
  background-color: #767676;
  width: 14.28%;
}

.stiky-bg-modal7 {
  background-color: #767676;
}
.generic-theme-color {
  color: #4c89dc !important;
  font-weight: 600;
}
.multi-hover:hover {
  background-color: #e0e0e0 !important;
}
.delete-modal {
  color: #e54545 !important;
}
.stiky-area {
  height: 160px;
}
.table-trash-icon {
  color: #e54545 !important;
  font-size: 14px !important;
}
tbody tr .table-trash-icon {
  color: #e54545 !important;
  font-size: 14px !important;
}
.Dribble-Website {
  color: #727274 !important;
}
.Development-text {
  color: #727274 !important;
}
.to-do-background {
  background-color: #fafbff !important;
  border-radius: 13px;
}

.Schedule-Meeting {
  color: #727274;
}

.add-scroll-card {
  height: 100% !important;
  overflow: scroll;
  overflow-x: hidden;
}
.link-underline {
  text-decoration: underline;
}
.Home-Table tbody tr:nth-of-type(even) {
  background-color: #fafbff !important;
}
.Home-Table tbody tr:nth-of-type(odd) {
  background-color: white !important;
}
.craete-card {
  align-self: center;
}
.UserImage.my-profile-img {
  height: 53px !important;
  width: 4rem !important;
  font-size: 19px;
}
.new-app-header .navbar-toggler {
  margin-right: 30px;
}
.custom-drpdown .dropdown-menu {
  position: absolute !important;
  inset: 0px auto auto -77px !important;
  margin: 0px !important;
  transform: translate3d(0px, 40px, 0px) !important;
}

.Home-buttons {
  background-color: #5e81f4 !important;
}
.view-all-button {
  border: 1px solid #8080801f;
  padding: 2px 10px 2px 10px;
  border-radius: 5px;
}
.botton-border {
  border: 2px dotted #4c89dc !important;
}
.stiky-notes-modale {
  background-color: #fff2ab !important;
}
.stiky-notes-bg {
  background-color: #fff7d1 !important;
}
.new-app-layout .updated-sidebar .tenant-name {
  font-size: 11px;
  font-weight: 700;
  color: white;
}
.test_this:before {
  position: fixed;
  top: 57px;
  bottom: 0;
  left: 75px;
  right: 0;
  background: rgba(100, 100, 100, 0.5);
  content: "";
  z-index: -4;
  pointer-events: none;
}
.inner-name {
  position: absolute;
  top: -2px;
  left: 0px;
}
.Take-Stiky-notes {
  height: 17em;
}

.checkbox-size [type="checkbox"] {
  font-size: 16px !important;
}

.calender-size-manage .fatsuA {
  width: 100% !important;
}
.form-group {
  margin-bottom: 0px !important;
}
.calender-size-manage .fatsuA .sc-dkrFOg {
  justify-content: space-around !important;
}
.calender-size-manage .fatsuA .sc-dkrFOg .kVEIdA {
  width: 24px !important;
  height: 24px !important;
  text-align: center !important;
  font-size: 13px !important;
  font-weight: 800 !important;
  font-family: inherit !important;
  color: #666 !important;
}

.calender-size-manage .fatsuA .sc-dkrFOg .cIFnvd {
  font-size: 13px !important;
  display: table-cell !important;
  font-weight: 700 !important;
}

.status-feild {
  min-width: 73px;
}
.meeting-card {
  background-color: #fafbff;
  border: 1px solid #d9d9d9;
  padding: 11px 8px 11px 9px;
  border-radius: 9px;
}
.custom-drpdown .dropdown-toggle::after {
  display: none !important;
}
.meetinf-date-card {
  padding: 13px;
  border-radius: 9px;
  align-self: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: #fafbff;
  border: 1px solid #5f82f4;
  border-radius: 9px;
}

.Upcomming-Meetings {
  color: #666666;
}
/* .new-app-layout .updated-sidebar .btn-hi {
    border-radius: 50px !important;
    color: black;
    background-color: #79cef2;
    font-size: 13px !important;
    width: 35px;
    height: 35px;
    font-weight: 600;
    text-align: center;
    padding: 7px 8px;
    border: 2px solid white;
  } */
.settings-bg-color {
  background-color: #002248;
}
.feedback-system {
  background-color: #414fa0;
}
.feedback-system-icon-color {
  color: #414fa0;
}
.setting-system-icon-color {
  color: #002248;
}
.updated-layout-tabs .nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-right: 2px solid #d4d4d4 !important;
  line-height: 10px;
  color: #888888 !important;
}
.new-app-header .navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 1px 1rem !important;
  align-items: center;
  justify-content: space-between;
}
.dropDown-stying {
  color: #fff;
  background: linear-gradient(90deg, #009eff -26.47%, #00f38d 127.21%);
  border: none;
  border-radius: 5px;
  padding: 5px 15px;
  font-weight: 500;
}
.updated-layout-tabs .nav-link.active {
  color: #1171d1 !important;
  border-bottom: 3px solid rgb(6, 132, 222);
}
.updated-layout-tabs .nav-tabs {
  border-bottom: 3px solid #adadad;
}
/* DrpDownHeadre CSS */
.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.menu .button,
.menu a {
  line-height: 1;
  text-decoration: none;
  white-space: nowrap;
  display: block;
  padding: 1rem 1.5rem 1rem 0.7rem;
}
.menu a,
.menu button,
.menu input,
.menu select {
  margin-bottom: 0;
}
.menu .button,
.menu a {
  line-height: 1.33 !important;
}
.read-more-button svg,
.smooth {
  transition: all 0.7s;
  -moz-transition: all 0.7s;
  -ms-transition: all 0.7s;
  -webkit-transition: all 0.7s;
}
.css-1i9kywd,
.default-link-subbar {
  position: relative;
}
.css-1i9kywd {
  display: block;
  font-weight: 700;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  background: inherit;
  color: #3e3e3e;
}
.sub-menu {
  display: none;
}

.animated--fast {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}
.css-qnpwi {
  box-shadow: 6px 6px 6px -6px rgb(0 0 0 / 50%);
  min-height: 100%;
  position: absolute;
  z-index: 999;
  top: 50px;
  width: 55rem;
  cursor: auto;
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
meter,
nav,
object,
ol,
output,
p,
pre,
progress,
q,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
ul,
var,
video {
  border: 0;
  /* outline: 0; */
  vertical-align: initial;
  /* background: transparent; */
  margin: 0;
  padding: 0;
}
*,
:after,
:before {
  box-sizing: border-box !important;
  outline: none;
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
font,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
meter,
nav,
object,
ol,
output,
p,
pre,
progress,
q,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
ul,
var,
video {
  border: 0;
  outline: 0;
  vertical-align: initial;
  background: transparent;
  margin: 0;
  padding: 0;
}
.mr0 {
  margin-right: 0 !important;
}

.clearfix {
  zoom: 1;
}
.pr0 {
  padding-right: 0 !important;
}
.css-aloouo {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0;
}
nav ul {
  list-style: none;
}
.nav-menu {
  cursor: pointer;
  padding: 16px 1px;
}
.menu a,
.menu button,
.menu input,
.menu select {
  margin-bottom: 0;
}

.menu .button,
.menu a {
  line-height: 1.33 !important;
}
.read-more-button svg,
.smooth {
  transition: all 0.7s;
  -moz-transition: all 0.7s;
  -ms-transition: all 0.7s;
  -webkit-transition: all 0.7s;
}
.css-1i9kywd,
.default-link-subbar {
  position: relative;
}
.css-1i9kywd {
  display: block;
  font-weight: 700;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  background: inherit;
  color: #3e3e3e;
}
a,
button {
  text-decoration: none;
  transition: background 0.3s, color 0.3s, border-color 0.3s;
  -moz-transition: background 0.3s, color 0.3s, border-color 0.3s;
  -o-transition: background 0.3s, color 0.3s, border-color 0.3s;
  -webkit-transition: background 0.3s, color 0.3s, border-color 0.3s;
}
.sub-menu {
  display: none;
}
.animated--fast {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}
.css-qnpwi {
  box-shadow: 6px 6px 6px -6px rgb(0 0 0 / 50%);
  min-height: 100%;
  position: absolute;
  z-index: 999;
  top: 50px;
  width: 55rem;
  cursor: auto;
}
.absolute-center-v,
.btn-icon-box span.btn-icon-span,
.cross-common,
.cross-common svg,
.info-icon-box,
.info-icon-box:after,
.info-icon-box:before,
.input-icon,
.input-icon-right,
.slider-btn-center .frame + div {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 100%;
}
.r10 {
  right: 10px;
}
.down-arrow-menu {
  width: 11px;
  height: 8px;
  padding-top: 2px;
}
.menu .button,
.menu a {
  line-height: 1.33 !important;
}
.css-1i9kywd {
  display: block;
  font-weight: 700;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  background: inherit;
  color: #3e3e3e;
}
.down-arrow-menu:after {
  -webkit-transform: rotate(140deg);
  transform: rotate(140deg);
  right: 1px;
}
.css-1l5knwp:after {
  background: #3e3e3e;
}
.down-arrow-menu:after,
.down-arrow-menu:before {
  content: "";
  width: 5px;
  top: 5px;
  height: 1px;
  position: absolute;
}
*,
:after,
:before {
  box-sizing: border-box !important;
  outline: none;
}
.default-link-subbar .default-link-subbar-line {
  bottom: -7px;
  left: 0;
  width: 0;
  height: 3px;
  display: block;
  position: inherit;
}
.position-relative-li {
  position: relative;
}
.sub-menu {
  display: none;
}

.animated--fast {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}
.css-qnpwi {
  box-shadow: 6px 6px 6px -6px rgb(0 0 0 / 50%);
  min-height: 100%;
  position: absolute;
  z-index: 999;
  top: 50px;
  width: 55rem;
  cursor: auto;
}
.bt {
  border-top: 1px solid #d4d4d4;
}
.css-13ab30w {
  background: #fff;
}
.container1,
.container-fluid1 {
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
.container1 {
  width: 1000px !important;
  max-width: 100%;
}
.pb24 {
  padding-bottom: 24px !important;
}
.spaceBetween {
  justify-content: space-between;
}
.pt24 {
  padding-top: 24px !important;
}
.relative {
  position: relative !important;
}
.css-qnpwi {
  box-shadow: 6px 6px 6px -6px rgb(0 0 0 / 50%);
  min-height: 100%;
  position: absolute;
  z-index: 999;
  top: 50px;
  width: 55rem;
  cursor: auto;
}
.css-13wqvet {
  flex: 1 1 auto;
  word-wrap: break-word;
  margin-right: auto;
}
.text-center {
  text-align: center;
}
.f16 {
  font-size: 16px;
  line-height: 20px;
}
.pb24 {
  padding-bottom: 24px !important;
}
.fw9 {
  font-weight: 900;
}
.css-e04fjx {
  padding: 0;
  margin: 0;
  -webkit-column-gap: 15px;
  grid-column-gap: 15px;
  column-gap: 15px;
}
.css-e04fjx > li > a {
  font-size: 14px;
  color: #3e3e3e;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: inline-block;
  padding: 5px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  width: auto;
  white-space: break-spaces;
  text-align: left !important;
}
.menu a,
.menu button,
.menu input,
.menu select {
  margin-bottom: 0;
}

.menu .button,
.menu a {
  line-height: 1.33 !important;
}
.css-1tmjqcs {
  float: right;
  width: 213px;
  height: 327px;
  overflow: hidden;
  position: relative;
}
.css-qnpwi {
  box-shadow: 6px 6px 6px -6px rgb(0 0 0 / 50%);
  min-height: 100%;
  position: absolute;
  z-index: 999;
  top: 50px;
  width: 55rem;
  cursor: auto;
}
.css-13wqvet {
  flex: 1 1 auto;
  word-wrap: break-word;
  margin-right: auto;
}
.css-6ok6q2 {
  position: relative;
  height: 0;
  width: 100%;
  overflow: hidden;
  padding-bottom: 153.52112676056336%;
}

.css-6ok6q1 {
  height: 327px;
  width: 213px;
}
.css-qnpwi {
  box-shadow: 6px 6px 6px -6px rgb(0 0 0 / 50%);
  min-height: 100%;
  position: absolute;
  z-index: 999;
  top: 50px;
  width: 55rem;
  cursor: auto;
}
.css-1te5b4g {
  position: relative;
  top: 0;
  left: -1px;
  min-width: calc(100% + 2px);
  max-width: 100%;
}
.default-link-subbar.hover-enabled:hover {
  background: #ffffff;
}
.css-1i9kywd:hover {
  color: #3e3e3e;
}
.menu .button,
.menu a {
  color: rgba(0, 0, 0, 0.5);
  border-right: 2px solid #d4d4d4;
  line-height: 1px;
  text-decoration: none;
  white-space: nowrap;
  display: block;
  padding: 1px 30px;
}
.nav-menu:hover .sub-menu {
  display: block;
  list-style: none;
  position: absolute;
  z-index: 99;
  height: 200px;
  overflow: auto;
  width: 100%;
}
.new-app-header .nav-menu:hover .sub-menu::-webkit-scrollbar {
  width: 4px !important;
  background-color: #f5f5f5 !important;
}

.new-app-header .nav-menu:hover .sub-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%) !important;
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #f5f5f5 !important;
}

.new-app-header .nav-menu:hover .sub-menu::-webkit-scrollbar-thumb {
  background-color: #0ae !important;
  min-height: 10px !important;
}

.r10 {
  right: 10px;
}

.down-arrow-menu {
  width: 11px;
  height: 8px;
  padding-top: 2px;
}
.absolute-center-v,
.btn-icon-box span.btn-icon-span,
.cross-common,
.cross-common svg,
.info-icon-box,
.info-icon-box:after,
.info-icon-box:before,
.input-icon,
.input-icon-right,
.slider-btn-center .frame + div {
  position: absolute;
  top: 41%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 100%;
}
.down-arrow-menu:after,
.down-arrow-menu:before {
  content: "";
  width: 5px;
  top: 5px;
  height: 1px;
  position: absolute;
}

.default-link-subbar.hover-enabled:hover .default-link-subbar-line {
  background: #368be6;
  width: 100%;
  transition: all 0.7s ease-in-out;
  -webkit-transition: all 0.7s ease-in-out;
}

.default-link-subbar.hover-enabled.active .default-link-subbar-line {
  background: #368be6;
  width: 100%;
  transition: all 0.7s ease-in-out;
  -webkit-transition: all 0.7s ease-in-out;
}

.down-arrow-menu:after {
  -webkit-transform: rotate(140deg);
  transform: rotate(140deg);
  right: 1px;
}

.css-1l5knwp:after {
  background: #3e3e3e;
}
.down-arrow-menu:after,
.down-arrow-menu:before {
  content: "";
  width: 5px;
  top: 5px;
  height: 1px;
  position: absolute;
}
:after,
:before {
  box-sizing: border-box !important;
  outline: none;
}
.sideNave-icon {
  font-size: 25px !important;
  color: #adadad;
}

.down-arrow-menu:before {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  left: 2px;
}
.css-1l5knwp:before {
  background: #3e3e3e;
}
:after,
:before {
  box-sizing: border-box !important;
  outline: none;
  cursor: pointer;
}
.r10 {
  right: 10px;
}
.updated-sidebar ul .side-link:hover .icons,
.updated-sidebar ul .side-link.active .icons {
  color: white;
}
.css-qnpwi {
  box-shadow: 6px 6px 6px -6px rgb(0 0 0 / 50%);
  min-height: 93%;
  position: absolute;
  z-index: 999;
  top: 58px;
  /* width: 9rem; */
  cursor: auto;
}
.updated-sidebar ul .side-link {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.div-rotation {
  width: max-content;
  background-color: #0073ea;
  transform: rotate(-90deg);
  font-size: 8px;
  padding: 5px 20px 5px 8px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-right: 42px;
}
.down-arrow-menu:after,
.down-arrow-menu:before {
  content: "";
  width: 5px;
  top: 5px;
  height: 1px;
  position: absolute;
}
.down-arrow-menu:before {
  -webkit-transform: rotate(40deg);
  transform: rotate(40deg);
  left: 2px;
}
.main-card-calender {
  height: calc(100vh - 105px) !important;
  overflow: auto;
}
.css-1l5knwp:before {
  background: #3e3e3e;
}
.circle-div {
  background-color: white;
  padding: 5px !important;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  margin-top: 12px;
  margin-bottom: 5px;
  position: relative;
  left: 36px;
  font-size: 18px;
}
.font-size-10 {
  font-size: 12px !important;
  width: 117px !important;
  height: 95px !important;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
}
.css-13wqvet {
  flex: 1 1 auto;
  word-wrap: break-word;
  margin-right: auto;
}
.arrw-icon-styling {
  position: absolute;
  left: 30px;
  font-size: 40px;
  top: 6px;
  color: #acaeb1;
}
.updated-sidebar ul .side-link:hover,
.updated-sidebar ul .side-link.active {
  background: linear-gradient(90deg, #009eff -26.47%, #00f38d 127.21%);
  color: #ffff;
  border-radius: 3px;
}
.my-purchased-text {
  font-size: 20px !important;
  font-weight: 600;
}
.after-click-icn {
  width: 40px;
}
.hover-effect:hover {
  color: rgb(200, 194, 194);
}
.sidebar-item-clicked {
  position: absolute;
  margin-left: 536px;
  z-index: -9;
  /* max-height: 469px; */
  /* overflow-y: scroll; */
  top: -8px;
  box-shadow: 0px 4px 4px 0px #00000040;
  cursor: default;
}
.xeven-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #4caf50;
  background-color: #4caf50;
}
.maxWidth-sidebar {
  width: 75px !important;
}
.updated-sidebar ul .side-link:hover,
.updated-sidebar ul .side-link.active {
  background: linear-gradient(90deg, #009eff -26.47%, #00f38d 127.21%);
  color: #ffff;
  border-radius: 3px;
}
.app-logo-width {
  margin-top: 3px;
  width: 53px !important;
}
.new-app-layout .custom-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  background-color: white;
  text-align: center;
  display: block;
}

.new-app-layout .content {
  margin-left: 75px;
  width: 96.1% !important;
}
@media only screen and (max-width: 1080px) {
  .navbar-expand-lg .navbar-toggler {
    display: block;
  }
  .collapse:not(.show) {
    display: none !important;
  }
  .css-13ab30w {
    background: #f1f1f1 !important;
  }
  .small-screen-padding {
    padding-left: 0px !important;
  }
  .small-screen-nargin-padding {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
  .menu {
    padding: 0;
    margin: 0;
    list-style: none;
    position: fixed;
    display: block;
    flex-wrap: wrap;
    right: 9px;
    background-color: #ffffff;
    top: 74px;
    border-radius: 5px;
    height: auto;
    overflow-y: auto;
  }

  .css-qnpwi {
    box-shadow: 6px 6px 6px -6px rgb(0 0 0 / 50%);
    min-height: 19%;
    left: -1px;
    top: auto !important;
    cursor: auto;
  }
  .nav-menu {
    cursor: pointer;
    padding: 12px 9px;
  }
  .menu a {
    border: none !important;
    padding: 2px 11px 1px 22px !important;
  }
  .r10 {
    left: 3px;
  }
  .new-app-header {
    background-color: white !important;
    padding: 12px 0px;
  }
  .new-app-headers {
    background-color: white !important;
    display: block !important;
  }
  .mini-header-custom-stylling {
    display: flex !important;
  }
}
.mini-header-custom-stylling {
  justify-content: end;
  margin: 2px 34px;
  background: #6e6bf4;
  position: fixed;
  right: -35px;
  top: -2px;
  padding: 9px;
}

@media only screen and (max-width: 1024px) {
  .main-first {
    width: 100%;
  }
}

/* .side-img-gradient{
    background-image: url('../images/krakenimages.png');
  } */
.Cloud-Optimized {
  top: 287px;
  font-size: 52px;
  left: -13px;
}

.grediend {
  left: 0;
}

.terms_conditions {
  visibility: hidden;
}
.Reason-Dropdown {
  border: 1px solid #e7ecfd;
  padding: 8px 10px;
  border-radius: 20px;
}
.candidateCardTitle {
  color: linear-gradient(90deg, #009eff -26.47%, #00f38d 127.21%) !important;
}
.candidateFileUploader .ant-upload.ant-upload-drag {
  min-height: 108px !important;
}
.d-charts .apexcharts-menu-icon{
  display: none;
}
.time-analysis-chart .apexcharts-legend-marker{
  border-radius: 0px !important;
}