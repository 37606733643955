@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

.loaderDiv {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  cursor: pointer;
}
.quickLinkContent {
  margin-bottom: 100px !important;
}
.loaderDiv .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.loaderDiv .spinner-body {
  width: 5rem;
  height: 5rem;
  color: white;
}
.recruitment-dashboard-tile .last-record h3 {
  line-height: 0.9;
}
.recruitment-dashboard-stats-card h5 {
  background-color: #f3f5f9;
  padding: 10px 0;
  color: #658cdb;
}
.prev-record {
  border-radius: 10px;
  background-color: #f3f5f9;
  padding: 5px 10px;
  color: #658cdb;
}
.prev-record h5 {
  color: #658cdb;
}
small {
  font-weight: 400;
  line-height: 1;
  color: #c5c6c7;
}
.custom-badge {
  display: flex;
  align-items: center;
  font-size: 12px;
  border-radius: 20px;
  padding: 2px 6px;
  color: white;
}
.emp_tabs .ant-tabs-tab {
  margin-right: 0px !important;
  padding: 12px 28px !important;
}
.emp_tabs .ant-tabs-nav {
  background-color: white;
}
.emp_tabs_vertical .ant-tabs-tab.ant-tabs-tab-disabled {
  cursor: auto !important;
}
.emp_tabs_vertical .ant-tabs-tab {
  margin: 0px !important;
}
.image_warning {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb !important;
  padding: 1rem !important;
}
/* .least-log-hours-data {
  height: 100%;
  min-height: 360px;
  overflow: auto;
  max-height: 360px;
  padding-right: 20px;
} */
.least-log-hours-data .ant-table-body::-webkit-scrollbar {
  width: 5px !important;
  background-color: #f5f5f5 !important;
}

.least-log-hours-data .ant-table-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%) !important;
  box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #f5f5f5 !important;
}

.least-log-hours-data .ant-table-body::-webkit-scrollbar-thumb {
  background-color: #0ae !important;
  min-height: 10px !important;
}
.least-log-hours-data .ant-table-body::-webkit-scrollbar:horizontal {
  height: 5px;
}
.dasboard-dropdown {
  font-family: "Nunito" !important;
}
.ceo-dropdown {
  background-color: #2094cd !important;
  color: white !important;
  border: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 16px;
}
.ceo-dropdown:focus {
  background-color: #2094cd !important;
}
.font-weight-700 {
  font-weight: 700;
}

@media screen and (max-width: 768px) and (min-width: 320px) {
  .department_performance {
    position: initial !important;
    cursor: default;
    width: 100% !important;
    height: none !important;
    display: block !important;
    text-align: center !important;
    height: auto !important;
  }
}
@media screen and (max-width: 768px) and (min-width: 320px) {
  .department_performance .recharts-legend-wrapper {
    position: relative !important;
    width: auto !important;
  }
}