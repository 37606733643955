.events-screen  .fc .fc-toolbar {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.events-screen .fc .fc-header-toolbar {
    background: linear-gradient(360deg, #009EFF -19.55%, #00F38D 114.92%);
    border-radius: 0px;
     margin-bottom: 0px !important; 
    padding: 17px !important;
}
.events-screen .fc .fc-toolbar-title{
    color: white !important;
}
.events-screen .fc .fc-today-button{
    display: none !important;
}
.events-screen .fc .fc-button-group{
    background-color: transparent !important;
    border: none !important;
}
.events-screen .fc .fc-button-primary{
    background-color: transparent !important;
    border: none !important;
}
.events-screen .fc .fc-daygrid-day-frame {
    position: relative;
    min-height: 100%;
    background: #FFFFFF !important;
border: 1px solid #E9E9E9 !important;  
}
/* .events-screen .fc-icon-chevron-left:before {
    content: "\e900";
    position: absolute !important;
    left: -261px !important;
} */
.scrolling-heght-events{
    height: calc(100vh);
    overflow: auto;

}
.scrolling-heght-events::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  
  .scrolling-heght-events::-webkit-scrollbar {
    width: 8px;
    background-color: #ffffff;
  }
  
  .scrolling-heght-events::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #5e81f4;
  }
  .events-screen .fc .fc-scrollgrid-sync-inner{
    padding: 10px;
    background-color: #F1FAFF;
}
.events-screen .fc-theme-standard td, .events-screen .fc-theme-standard th {
    border: none !important;
}
.events-screen .fc .fc-scrollgrid-liquid {
    border: none;
}
.events-screen .fc .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 2px 4px;
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;

color: #232A3E;
}
.events-screen .fc .fc-daygrid-day-number {
    position: relative;
    z-index: 4;
    padding: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    
    color: #232A3E;
}