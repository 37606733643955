@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.generic-page-title {
  color: #666666;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 32px;
}
.generic-card-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: #009eff;
}
.upload_Slip {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: #009eff;
}
.generic-rounded-style {
  border-radius: 6px;
}
.ant-btn-primary {
  border-radius: 5px !important;
  background-image: linear-gradient(to right, #009eff, #00f38d) !important;
  color: #fff;
  border: none !important;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.02) !important;
}
.ant-select {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
  border-radius: 0px !important;
}
.ant-form-item {
  margin-bottom: 0px !important;
  display: block !important;
}
.generic-title {
  color: #009eff;
  /* text-shadow: 0 0 .25px currentColor;
  font-size: 18px;
  font-family: "Inter"; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
}
.custom-style-cell-delete {
  padding: 6px;
  /* border: 1px solid #d9d9d9; */
  border-radius: 5px;
  text-align: center;
  /* background-color: white; */
}
.download_color{
color: #7291F6;
font-family: inter;
font-weight: 500;
font-size: 16px;
}
.candidate-job-detail{
  color: #009EFF;
  font-family: inter;
font-weight: 500;
font-size: 15px;
}
.new_links_salary{
  color: #2A2B2F;
  font-family: inter;
font-weight: 500;
font-size: 16px;
}
.detail_info{
  color: #ADADAD;
  font-family: 'inter';
font-weight: 500;
font-size: 16px;
}

.profile_head{
  font-size: 20px;
  font-weight: 600;
  font-family: 'Inter';
  color: black;
}
.all_info-profile{
  font-size: 12px;
  font-family: 'Inter';
  color: #969696;
}
.side-loGos{
  color: #969696;
font-size: 17px;
}
.all_info-profile2{
  font-size: 14px;
  font-family: 'Inter';
  font-weight: 500;
  color: #969696;
}
.site-card-wrapper .profile_card_candidate .ant-card-body{
padding: 0px !important;
border-radius: 5px !important;
}
.links_websites-url{
  text-decoration: underline;
  color: #009EFF !important;
  font-family: 'inter';
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}
.Resume_head{
font-family: inter;
font-weight: 500;
font-size: 16px;
color: #666666;
}
.not-sent-status {
  background: rgba(229, 69, 69, 0.1);
  border-radius: 24px;
  color: #e54545;
  text-align: center;
  font-weight: 500;
}
.pending-status {
  background: #efefef;
  border-radius: 24px;
  color: #adadad;
  text-align: center;
  font-weight: 500;
}
.div-border-radius {
  /* height: calc(100vh - 145px); */
  overflow: auto;
}
.paidUnApproved-status {
  background: rgba(240, 209, 95, 0.13);
  border-radius: 24px;
  color: #dfbf49;
  text-align: center;
  font-weight: 500;
}
.sent-status {
  background: rgba(48, 235, 101, 0.08);
  border-radius: 24px;
  color: #30eb65;
  text-align: center;
  font-weight: 500;
}
.clear-btn {
  color: #adadad;
  font-weight: 500;
  cursor: pointer;
}
.skills-card-body-bg-color .ant-card-body {
  padding: 0px 5px !important;
}
.cursor {
  cursor: pointer;
}
/* .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #D9D9D9;
  border: 1px solid #e6ebf1;
  border-radius: 5px;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
} */
.ant-tabs {
  color: #666666 !important;
}
.compation .ant-form-item {
  margin-bottom: 0px !important;
}
.generic-color-font-faimly {
  color: #009eff;
  /* text-shadow: 0 0 .25px currentColor;
  font-size: 18px;
  font-family: "Inter"; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
}
.generic-color-info {
  color: #009eff;
}
.text_undeline {
  text-decoration: underline;
  color: #009eff;
}
.recruitment-dashboard-tile .ant-card-body {
  padding-bottom: 10px;
}
.buisness-dashboard-tile .ant-card-body {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 12px;
}
.customize-progress-bar .ant-progress-bg {
  position: relative;
  background-color: #00d179;
}
.new-dashboard-funeel {
  height: 18px;
  width: 18px;
  background-color: rgb(94, 129, 244);
}
.apexcharts-grid-row {
  fill: rgb(255, 255, 255);
}
.source-trends
  .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center,
.source-trends
  .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
  justify-content: space-around;
}
.new-dashboard-funeel1 {
  height: 18px;
  width: 18px;
  background-color: rgb(229, 69, 69);
}
.new-dashboard-funeel2 {
  height: 18px;
  width: 18px;
  background-color: rgb(240, 209, 95);
}
.new-dashboard-funeel3 {
  height: 18px;
  width: 18px;
  background-color: rgb(0, 209, 121);
}
.funnel_graph {
  font-weight: 700;
}
.aroow-up-font-color {
  color: #00d179;
}
.ant-table-thead th.ant-table-column-sort {
  background-color: rgba(245, 245, 250, 0.4) !important;
}
.ant-table-tbody > tr.ant-table-row:hover > td,
td.ant-table-column-sort {
  background: transparent !important;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  display: none;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 0.625rem;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}
.ant-table-column-sorters {
  display: inline-flex;
  align-items: center;
  padding: 16px;
}
.status-accept-styling {
  background: #30eb65;
  color: #30eb65;
}
.status-reject-styling {
  background: #e54545;
  color: #e54545;
}
.status-unpaid-styling {
  background: #009EFF;
  color: #009EFF;
}
.cutom-style-datepicker .ant-picker {
  box-sizing: border-box;
  margin: 0;
  border-radius: 6px !important;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  padding: 4.5px 11px !important;
  background-color: #fafafa !important;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
  align-items: center;
  background: #fff;
  border: 1px solid #e6ebf1;
  transition: border 0.3s, box-shadow 0.3s;
}
.ant-picker {
  box-sizing: border-box;
  margin: 0;
  border-radius: 6px !important;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  padding: 8.5px 11px;
  color: #455560;
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #e6ebf1;
  border-radius: 4px !important;
  transition: all 0.3s;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background-color: rgba(245, 245, 250, 0.4) !important;
}
.btn-report-styling {
  height: 29px !important;
  line-height: 0.5 !important;
}
/* .ant-tooltip-open{
  background-color: red;
} */
.text-style-tiles {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #666666;
}
.text-bold-500 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 32px;

  color: #1c1d21;
}
.font-size {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}
.muted-font-color {
  color: #666666;
}
.btn-report-styling-listing {
  height: 29px !important;
  line-height: 0.5 !important;
  background-color: transparent;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  cursor: pointer;
  font-size: 14px;
  line-height: 100%;
  padding: 4px 9px;
  color: #009eff;
  border: 2px solid #009eff !important;
  border-radius: 20px;
}
.fc-h-event .fc-event-title-container {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 0;
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 114% */

  /* Style */

  color: #ffffff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.fc-daygrid-block-event {
  border-radius: 9px !important;
  margin-top: 6px !important;
}
.muted-font-color-png {
  color: #adadad;
}
.border-choose-file {
  border: 2px dashed #d9d9d9;
  padding: 20px;
  border-radius: 6px;
}
.muted-font-color-image-icon {
  color: #d8dae5;
}
.scrolling-div-employees {
  height: calc(100vh - 162px);
  overflow: auto;
}
.scrolling-div-employees::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scrolling-div-employees::-webkit-scrollbar {
  width: 8px;
  background-color: #ffffff;
}

.scrolling-div-employees::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #5e81f4;
}
.div-border-radius::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.div-border-radius::-webkit-scrollbar {
  width: 8px;
  background-color: #ffffff;
}

.div-border-radius::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #5e81f4;
}
.ant-descriptions-item-label::after,
.ant-form-item-label > label::after {
  content: "" !important;
  position: relative;
  top: -0.5px;
  margin: 0px 8px 0px 2px;
}

.choose-file .ant-btn-primary {
  border-radius: 5px !important;
  /* font-size: 15px !important; */
  color: #666666 !important;
  font-weight: 400 !important;
  background-color: #fafafa !important;
  background-image: none !important;
  border: 1px solid #d9d9d9 !important;
}
.highcharts-credits {
  display: none !important;
}
.generic-input-feelds-style {
  background: #ffffff !important;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.02) !important;
  border-radius: 7px !important;
}
.ant-table-tbody tr td {
  border-bottom: 0px !important;
}
.ant-table-tbody tr td tr:nth-of-type(odd) {
  background-color: white !important;
}
.active-status {
  background-color: rgba(48, 235, 101, 0.08);
  padding: 4px 20px 4px 20px;
  color: #30eb65;
  border-radius: 27px;
  border-radius: 20px;
  font-size: 11px;
  line-height: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
}
.late_comming {
  background-color: rgba(252, 211, 84, 0.08);
  padding: 4px 20px 4px 20px;
  color: #edc13a;
  border-radius: 27px;
  border-radius: 20px;
  font-size: 11px;
  line-height: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
}
.on_timing-status {
  background-color: rgba(48, 235, 101, 0.08);
  padding: 4px 20px 4px 20px;
  color: #00d9a3;
  border-radius: 27px;
  border-radius: 20px;
  font-size: 11px;
  line-height: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
}
.inactive-status {
  background-color: #f1f4ff;
  padding: 5px 20px 5px 20px;
  color: #666666;
  border-radius: 20px;
  font-size: 11px;
  line-height: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #3e79f7;
  font-family: "Inter";
  border-color: #3e79f7;
  border-radius: 5px !important;
  color: white !important;
}
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
  border-radius: 5px !important;
  background-color: #f5f5f5 !important;
  color: #adadad !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.last-arrow-table {
  width: 12px !important;
}
.Add-icon-Pool {
  font-size: 15px !important;
}
/* .ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: #ADADAD !important;
  transition: none;
  background-color: #F5F5F5 !important;
  border-radius: 5px !important;
} */
.add-employee .details-section {
  border-bottom: 1px solid #f6f6f6;
  padding: 15px;
  cursor: pointer;
}
.add-employee .tab-buttons-active {
  border: none;
  background: -webkit-linear-gradient(#009eff, #00f38d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #00f38d;
  font-weight: 600;
  font-size: 18px;
}
.upload-file-text-style {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #1c1d21;
}
.icon-font-size {
  font-size: 20px;
}
.add-employee .tab-buttons-default {
  border: none;
  color: #adadad;
  background-color: transparent;
}
.chose-file-img-styling {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #009eff;
  text-shadow: 0 0 0.25px currentColor;
  font-size: 18px;
}
.emp_tabs .ant-tabs-tab {
  margin-right: 0px !important;
  padding: 12px 28px !important;
  font-size: 18px;
  color: #adadad !important;
}
.emp_tabs .ant-tabs-nav {
  margin-bottom: 0px !important;
}
.emp_tabs .ant-card-body {
  padding-top: 10px !important;
}
/* .ant-table-tbody tr .ant-table-row-expand-icon-cell{
  position: absolute !important;
  right: 15px !important;
} */
.ant-card-bordered {
  border-radius: 6 !important;
  border: none !important;
}
.border-styling-arroow-up-down {
  background: #fafbff;
  /* Primary Color */
  color: #5e81f4;
  border: 1px solid #5e81f4;
  border-radius: 3px;
  transform: matrix(1, 0, 0, -1, 0, 0);
}
/* files css */
.Pricing_cell {
  text-align: center;
  border-left: 1px solid #e9e9e9;
}
.back-manue {
  position: absolute;
  z-index: 99;
}
.plan-name {
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
}
.pricing_details {
  display: flex;
  justify-content: center;
  margin: 0 0 15px;
  color: #5d5d66;
  font-size: "12px";
}
@media screen and (max-width: 991px) and (min-width: 100px) {
  .min-screen-view {
    display: none;
  }
  .back-manue {
    display: block !important;
  }
}
.pricing a {
  background: #fff;
  border: 1px solid #0067ff;
  color: #0067ff;
  min-width: inherit;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px 8px;
  transition: all ease 0.2s;
}
.dollar_sign {
  color: #5d5d66;
}
.rate_list {
  font-size: 36px;
}
.plus-icon {
  border: 1px solid black;
  border-radius: 50%;
  padding: 2px 8px;
}

.bot-boder {
  border-bottom: 1px solid #e9e9e9;
}
.Automation-Sales {
  font-weight: 600;
}
.projects_space {
  border-left: 1px solid #e9e9e9;
}
@media screen and(max-width: 768px) and(min-width: 320px) {
  .client_Satisfaction_Analysis {
    display: block !important;
  }
}
@media screen and(max-width: 768px) and(min-width: 320px) {
  .card-margin-0 {
    margin: 0 0 10px !important;
  }
  .graph-margin-bottom-smal-screen {
    padding-top: 40px !important;
  }
}
.text-styling {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
}
.modal-icon-dashboard {
  font-size: 45px;
  cursor: pointer;
}

#user-welcome-card {
  border-radius: 12% !important;
}
.button-sizes {
  font-size: 12px !important;
  font-family: "Inter";
  font-weight: 500;
}
.text-danger-custom {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #e54545;
}
.folder_background {
  /* background-color: rgb(246, 249, 251); */
  border-radius: 20px;
  padding: 10px !important;
  margin-bottom: 24px !important;
}
.folder_data {
  position: relative !important;
  border-radius: 8px !important;
  cursor: pointer !important;
  flex-direction: column !important;
  height: 170px !important;
  min-height: 170px !important;
  display: flex !important;
  margin: 10px !important;
  margin-top: 10px !important;
  flex-direction: column !important;
  background-color: rgb(255, 255, 255);
  width: 170px !important;
  border: 1px solid #d9d9d9;
  min-width: 170px !important;
  /* box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important; */
}
.files_tab .nav-link.active {
  background-color: #004660 !important;
  color: #fff !important;
  border-right: 1px solid #dee2e6 !important;
}
.font-size-manue-icn {
  font-size: 100px !important;
  height: 32px !important;
  width: 32px !important;
}
.files_tab .nav-tabs a {
  color: black !important;
  border-right: 1px solid #dee2e6 !important;
}
.files_badge .badge {
  border: 1px solid #dee2e6 !important;
  /* padding: 0.5rem !important; */
  cursor: pointer !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.activeBadge {
  background-color: #00f38d !important;
  color: white;
}
.empty_file {
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
  font-size: 56px;
  line-height: 56;
  color: rgba(0, 0, 0, 0.26);
}
.empty_file-full-view {
  width: 250px;
  height: 250px;
  min-width: 250px;
  min-height: 250px;
  font-size: 56px;
  line-height: 56;
  color: rgba(0, 0, 0, 0.26);
}
.empty_file-docs {
  width: 150px;
  height: 150px;
  min-width: 150px;
  min-height: 150px;
  font-size: 56px;
  line-height: 56;
  color: rgba(0, 0, 0, 0.26);
}
.file_close {
  margin-bottom: 10px;
  width: 26px;
  height: 26px;
  min-width: 26px;
  min-height: 26px;
  font-size: 26px;
  line-height: 26;
  color: rgba(0, 0, 0, 0.26);
  cursor: pointer;
}
.file_type_pdf {
  color: white;
  font-weight: 600;

  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 5px;
  bottom: -5px;
  font-size: 12px;
  right: 22px;
  position: absolute;
  background-color: rgb(229, 57, 53);
}
.file_type_xls {
  color: white;
  font-weight: 600;

  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 5px;
  bottom: -5px;
  font-size: 12px;
  right: 22px;
  position: absolute;
  background-color: rgb(67, 160, 71);
}
.file_type_doc {
  color: white;
  font-weight: 600;

  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 5px;
  bottom: -5px;
  font-size: 12px;
  right: 22px;
  position: absolute;
  background-color: rgb(30, 136, 229);
}
.file_type_txt {
  color: white;
  font-weight: 600;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 5px;
  bottom: -5px;
  font-size: 12px;
  right: 22px;
  position: absolute;
  background-color: rgb(117, 117, 117);
}
.file_type_jpg {
  color: white;
  font-weight: 600;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 5px;
  bottom: -5px;
  font-size: 12px;
  right: 22px;
  position: absolute;
  background-color: rgb(255, 179, 0);
}
.file_type_zip {
  color: white;
  font-weight: 600;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 5px;
  bottom: -5px;
  font-size: 12px;
  right: 22px;
  position: absolute;
  background-color: #facc14;
}
.file_type_mp3 {
  color: white;
  font-weight: 600;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 5px;
  bottom: -5px;
  font-size: 12px;
  right: 22px;
  position: absolute;
  background-color: #f05783;
}
.file_type_mp4 {
  color: white;
  font-weight: 600;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  border-radius: 5px;
  bottom: -5px;
  font-size: 12px;
  right: 22px;
  position: absolute;
  background-color: #38a3ff;
}
.file_style {
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}

.file_manager {
  background-color: rgb(246, 249, 251);
}
.file_information_label {
  font-weight: 400 !important;
  color: rgb(107, 114, 128) !important;
}

.file_information_budget {
  font-weight: 550 !important;
  font-size: 1.3rem !important;
}
.file_information_data {
  font-weight: 550 !important;
  font-size: 1.2rem !important;
  margin-top: 1.2rem !important;
}

.folder_info_icon svg {
  color: #adadad !important;
  height: 22px !important;
  width: 22px !important;
  border: 1px solid #d9d9d9;
  padding: 3px;
  border-radius: 4px;
}
.folder_info_icon {
  position: absolute;
  right: 0%;
  top: 0%;
  margin: 8px 9px 8px 8px;
}
.folder_info_icon svg:hover {
  background: #eeeeee;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 4px #eeeeee;
}
.folder_info_icon .font-size-manue-icn:hover {
  color: #707070 !important;
  height: 32px !important;
  width: 32px !important;
}
.folder_info_icon .font-size-manue-icn {
  color: #707070 !important;
  height: 32px !important;
  width: 32px !important;
}
.truncate {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: inline-block !important;
  width: 170px !important;
}
.folder_files {
  background-color: #f6f9fb;
  height: 256px !important;
  border-radius: 20px;
}
.file_modal_header {
  background-color: #004660 !important;
}
.file_modal_header .modal-title {
  color: white;
  font-size: 20px !important;
}
.file_modal_btn {
  background-color: #004660 !important;
}
.file_modal_header .close {
  color: white !important;
}
.project_edit_icon {
  position: absolute !important;
  top: 106px !important;
  opacity: 0 !important;
  left: 56px;
}

.project_edit_icon_btn {
  font-size: 15px !important;
  cursor: pointer !important;
  pointer-events: auto;
  font-weight: 600;
}
.test span {
  cursor: pointer !important;
}

.filter-dropdown .dropdown-toggle::after {
  display: none;
}

.dropdown.show.filter-dropdown .dropdown-menu.show {
  margin: 0px;
  left: -41.5vh;
  width: 50vh;
  margin-top: 10px !important;
}
.dropdown.show.filter-dropdown:before {
  position: fixed;
  top: 57px;
  bottom: 0;
  left: 75px;
  right: 0;
  background: rgba(100, 100, 100, 0.5);
  content: "";
  z-index: 1;
  pointer-events: none;
}
.test_this:before {
  position: fixed;
  top: 57px;
  bottom: 0;
  left: 75px;
  right: 0;
  background: rgba(100, 100, 100, 0.5);
  content: "";
  z-index: -4;
  pointer-events: none;
}
.kanban-header .navbar-brand {
  border: 1px solid #004660 !important;
}
.filter-dropdown .show > .btn-primary.dropdown-toggle {
  border: 1px solid #004660 !important;
  color: #50b7ec !important;
  background-color: none !important;
}
#filter_dropdown .btn-primary {
  border: 1px solid #004660 !important;
  color: #50b7ec !important;
  background-color: none !important;
}
.hrm-dashboard-btn {
    color: #fff;
    background: linear-gradient(90deg, #009eff -26.47%, #00f38d 127.21%);
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-weight: 500;
}
/* .dropdown-toggle[aria-expanded="true"] {
            background: #fff !important;
            color: #000 !important;
          } */
.filter_reset_btn {
  background-color: #e9ecef !important;
  color: #2b2b2b !important;
  border: #e9ecef !important;
}
.backlog_clr_btn {
  color: rgb(107, 114, 128) !important;
}
.sprint_clr_btn {
  color: rgb(107, 114, 128) !important;
}
.case_Type_label {
  color: #adadad;
  font-weight: 400 !important;
  font-size: 13px !important;
  font-family: "Inter";
}
.test_case_title {
  font-weight: 600 !important;
  font-size: 20px !important;

  margin-bottom: 0% !important;
}

.testCase_Report_Header {
  background-color: #ffffff !important;
  align-items: center !important;
}
.testCase_Report_title {
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #5e81f4 !important;
  margin-bottom: 0% !important;
}
.testCase_passed_ratio {
  font-weight: 600 !important;
  font-size: 45.71px !important;
  color: #666666 !important;
}
.testCase_Protocol_graph {
  background-color: rgba(245, 245, 250, 0.4);
}
.testCase_History {
  background-color: #fafbff;
}
.testCase_report_graph .apexcharts-legend.apx-legend-position-right {
  justify-content: center !important;
}
.testCase_report_graph .apexcharts-legend-marker {
  width: 15px !important;
  height: 15px !important;
  top: 3px !important;
  border-radius: 0px !important;
}
.testCase_report_graph .apexcharts-legend-text {
  margin-left: 0.25rem !important;
}
.testCase_report_graph .apexcharts-datalabels {
  font-weight: 600 !important;
  font-size: 25.7102px !important;
}
.project_select {
  color: #666666 !important;
  background-color: #fafafa !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}
.project_clr_btn {
  color: #adadad !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}
input::-webkit-input-placeholder {
  font-weight: 400 !important;
  font-size: 12px !important;
  /* line-height: 15px !important; */
  color: #adadad !important;
  font-family: "Inter";
}

.dataTables_wrapper .rdt_TableRow {
  border: none !important;
}
.dataTables_wrapper .cxcOiE {
  background-color: #fafbff !important;
}
.dataTables_wrapper .efVAPp {
  background-color: #ffffff !important;
}
.dataTables_wrapper .rdt_TableHeader {
  border: none !important;
}
.dataTables_wrapper .iSAVrt {
  border: none !important;
}

.dataTables_wrapper .jzkjuR {
  background-color: #ffffff !important;
}
.dataTables_wrapper .dwPlXY {
  background-color: #fafbff !important;
}
.pass_badge {
  background-color: #f1ffe8;
  color: #39c760;
  font-weight: 500;
  font-size: 14px;
}
.fail_badge {
  background-color: #fcecec;
  color: #fb4d4d;
  font-weight: 500;
  font-size: 14px;
}
.NA_badge {
  background-color: #efefef;
  color: #adadad;
  font-weight: 500;
  font-size: 14px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
/* ///// bage */
.ant-badge {
  border-radius: 10rem;
  border: 1px solid #dee2e6 !important;
  cursor: pointer !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.skills-card-body-bg-color {
  background: #fafafa;
}

.skills-table tr {
  border: 0;
  background-color: #fafafaed !important;
  outline: 0;
  vertical-align: initial;
  margin: 0;
  padding: 0;
}
.skills-table thead th {
  background-color: #fafafaed !important;
}
.arrow-upDown {
  width: 25px;
  text-align: center;
  height: 25px;
  color: #009eff;
  background: #d5daf0;
  border: 0.4px solid #009eff;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  position: absolute !important;
  top: 10px !important;
  right: 14px !important;
  left: auto !important;
  display: inline-block;
  padding: 5px 0px 0px;
  font-size: 12px;
}
.skills-table thead th {
  background-color: #f4f4f4 !important;
}
.ant-collapse {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  color: rgb(69, 85, 96);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  background-color: rgb(250, 250, 250);
  border-image: initial;
  border-bottom-style: initial;
  border-bottom-color: initial;
  border-radius: 0px !important;
  border: none !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 18px 16px 18px 18px !important;
  line-height: 1.5;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
}
.ant-collapse-content {
  background-color: #fafafa;
  border-top: 1px solid #e6ebf1;
}
.generic-bg-color {
  background-color: #fafafa;
}
.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow
  svg {
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s;
  background-color: #d5daf0 !important;
  width: 26px !important;
  height: 26px !important;
  padding: 8px !important;
  border: 1px solid #009eff !important;
}
/* .ant-card-bordered{
  border-radius: 0 !important;
} */
.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow
  svg {
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s;
  width: 26px !important;
  height: 26px !important;
  padding: 8px !important;

  background: #fafbff !important;
  color: #009eff !important;
  border: 0.4px solid #009eff !important;
  border-radius: 5px !important;
  /* transform: matrix(1, 0, 0, -1, 0, 0) !important; */
}

.add-employee .ant-progress-line {
  /* position: relative; */
  width: 30% !important;
  font-size: 14px;
  margin-left: 8px;
  margin-bottom: 3px;
}
.add-employee .ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #d3dbf4;
  border-radius: 100px;
}
.add-employee .ant-progress-text {
  display: none;
  width: 2em;
  margin-left: 0px;
  color: #455560;
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 0px);
}
.add-employee .ant-progress-bg {
  position: relative;
  width: 30% !important;
  background: linear-gradient(90deg, #00f38d 1.89%, #04da80 98.11%);
}

.arrow-table {
  font-size: 13px;
  color: #666666 !important;
  font-weight: 600;
}
.least_logged_hours .ant-card-body {
  padding-bottom: 0px !important;
}
.text-succes-custom {
  color: #00f38d;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}
.dep-perfoemaance .ant-card-body {
  height: 100% !important;
}
.customized-height .ant-card-body {
  height: 100%;
}
.customized-height .ant-table-tbody {
  height: 100%;
}
.customized-height .ant-spin-nested-loading {
  height: 100%;
}
.customized-height .ant-spin-container {
  height: 100%;
}
.customized-height table {
  height: 100%;
}
.customized-height .ant-table-content {
  height: 100%;
}
.ant-table-tbody tr td {
  border-bottom: 0px !important;
  vertical-align: middle !important;
}
.customized-height .ant-table-container {
  height: 100%;
}
.customized-height .ant-table-scroll-horizontal {
  height: 66%;
}
.attendance .apexcharts-legend-text {
  color: rgb(69, 85, 96) !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
.attendance .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-left,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-left,
.apexcharts-legend.apx-legend-position-right,
.apexcharts-legend.apx-legend-position-left {
  justify-content: space-evenly !important;
  margin-bottom: 17px !important;
  position: absolute !important;
  left: auto !important;
  top: 24px !important;
  right: -13px !important;
}

.attendance .apexcharts-legend-marker {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-right: 5px !important;
  /* margin-top: 2px !important; */
  height: 16px !important;
  width: 16px !important;
  border-radius: 0px !important;
  border-style: solid;
}
.card-margin-0 {
  margin: 0 0 0px !important;
}
.card-margin-0 .ant-table-pagination.ant-pagination {
  margin: 17px 18px;
}
.hiring-buttons .hiring-tabs {
  background-color: #ffffff !important;
  border: none;
  border-radius: 4px;
  color: #adadad !important;
}
.hiring-buttons .hiring-tabs-seelcted {
  background: #009eff !important;
  border: 1px solid #009eff !important;
  border-radius: 4px;
  color: #ffffff !important;
}

.hiring-buttons .active-hiring-tabs {
  background-color: #009eff !important;
  border: 1px solid #009eff !important;
  border-radius: 4px;
  color: white !important;
}

.bottom-heading {
  font-size: 9px;
  color: #adadad;
}
.application-heading {
  font-size: 13px;
  color: #adadad;
}

.dot-net-developer {
  color: #009eff;
  font-family: "Inter";
  font-weight: 500;
}

.heading-title-level-5 {
  color: #00d2e3;
  font-family: "Inter";
  font-weight: 500;
}

.hirig-dot-net {
  color: #666666;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 32px;
}
.genereic-dropdown-btn {
  border-radius: 5px !important;
  background-image: linear-gradient(90deg, #009eff, #00f38d) !important;
  color: #fff;
  border: none !important;
  box-shadow: 0 0 26px rgba(0, 0, 0, 0.02) !important;
}

.back-generic-btn {
  border-radius: 5px !important;
  background-color: #d9d9d9 !important;
  color: #666666;
  border: none !important;
  font-family: "Inter";
  font-weight: 500;
  box-shadow: 0px 0px 26px rgb(0 0 0 / 2%) !important;
}

.new-samll-color {
  color: #adadad;
  font-size: 12px;
}

.recruitment-applicant-pipeline .applicant-pipeline-card .ant-card-body{
  padding: 28px 20px 20px;
}
.recruitment-applicant-pipeline .applicant-onboard-heading{
  font-size: 1.2rem !important;
}