.DropDown-Dashboard{
    width: 12rem !important;
}

.Hiring-Dashboard{
    margin-bottom: 2%;
    background-color: white;
    border: none;
    font-weight: 600;
}

.Accordian-Collaps{
    border: none;
}

.Description-Job{
height: 120px !important;
}

.plus-icon-btn{
    font-size: 11px !important;
}
.ant-input{
    border-radius: 3px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(230, 235, 241);
    border-radius: 0.625rem;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    border-radius: 6px;
    color: #ADADAD !important;
    font-size: 12px !important;
    font-family: 'Inter' !important;
    font-weight: 400 !important;
}

  table tbody tr:nth-of-type(even) {
    background-color: white !important;

  }
  table tbody tr:nth-of-type(odd) {
    background-color: #fafbff !important;

  }

  .ant-table-thead tr{
    border-bottom: none !important;
  }
  .ant-table-thead tr th{
    background-color: rgba(245, 245, 250, 0.4);
    border-radius: 0px !important;
    padding: 10px 18px 10px 14px;
  }

  table tbody thead tr th{
    border: none !important;
    font-size: 12px !important;
    color: black !important;
    text-transform: capitalize !important;
    background-color: #fafbff !important;
  }

  tbody tr td {
    font-size: 12px !important;
    color: #727274 !important;
    border: none !important;
}

.ant-table-cell{
    border: none !important;
}

.Search-table{
    box-shadow: none !important;
    padding: 0px 25px 0px 9px !important;
    color: #ADADAD !important;
}
.searchbar-table{
    border-radius: 5px !important;
    border: 1px solid #D9D9D9;
    height: 36px;
}
.search-icon-color{
    font-size: 16px !important;
    color: #ADADAD;
}
.set-table-card .ant-card-body{
     padding: 22px 0px 10px 0px !important;
}

.set-table-card{
    border-radius: 5px !important;
    border: none !important;
}
.set-table-card .search-filter-dropdown .ant-select-selector{
    width: auto !important;
    background-color: #FAFAFA !important;
    color: #666666 !important;
    font-family: 'Inter';
    font-weight: 400;
    border-radius: 5px;
    font-size: 12px !important;
    height: 30px;
    display: flex;
    align-items: center;
}

.set-table-card  .ant-table-pagination{
    margin-right: 15px !important;
}

.search-filter-dropdown .ant-select-show-arrow .ant-select-arrow{
    color: #393838 !important;
    font-size: 10px;
}
.clear-filter{
    color: #ADADAD;
    font-size: 12px;
}

.Existing-Type{
    color: #1C1D21;
    font-family: 'Inter';
font-weight: 500;
font-size: 14px;
}
.small-expense-modal{
    height: 200px !important;
    overflow: auto;
}
.All-modal-set .ant-modal-body{
    padding: 9px 24px 24px 24px !important;
}
.candidate_profile{
    background-color: #009EFF !important;
    height: 194px;
    border-top-right-radius: 7px !important;
    width: 100%;
    border-top-left-radius: 7px;
}
.second_section{
    position: relative;
    top: 125px;
    background-color: #00D9A3;
}
.profile-logo-center{
    width: 38%;
    background: white;
    border-radius: 61px;
    padding: 24px;
    height: 126px;
    position: absolute;
    top: 125px;
    border: 1px solid #009EFF;
}
.software_development{
color:#009EFF;
font-family: inter;
font-weight: 500;
}
.Final_Interview{
    background-color: #E4F4FD;
    padding: 4px 15px 3px 15px;
    border-radius: 18px;
    color: #2BAEFF;
    font-size: 13px;
    font-family: 'Inter';
    font-weight: 600;
}
.profile-board .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #009eff;
    text-shadow: 0 0 0.25px currentColor;
    font-size: 18px;
}
.Notes_heading{
    color: #797979;
}
.Candidate0-Interview-notes{
    font-weight: 600;
    font-family: 'inter';
    font-size: 14px;
    color: black;
}
.notes-date-text{
    font-size: 12px;
    font-weight: 500;
}
.jobdetail-board-txt1{
    font-family: inter;
    font-weight: 500;
    color: #797979;
    font-size: 14px;
}
.jobdetail-board-txt2{
    font-family: inter;
    font-weight: 600;
    color: black;
    font-size: 14px;
}
.board-tabs-profile{
    font-family: 'inter';
    font-weight: 500;
}
.edit-description-board{
    color: #009EFF;
    font-size: 13px;
    font-family: inter;
    font-weight: 400;
}
.board-title .ellipsis-dropdown{
    transform: rotate(179deg) !important;
}
.board-title h4{
    font-size: 15px !important;
    font-family: 'inter' !important;
    font-weight: 600 !important;
}
.pricing_amonut-profile{
    font-weight: 500;
    font-family: 'Inter';
    color: #666666;
    font-size: 17px;
}
.profile_card_candidate{
    height: calc(100vh - 20px) !important;
}
.custompro-file_tabs{
    height: calc(100vh - 20px) !important;
}
.custompro-file_tabs .ant-tabs-tab-active{
    background-color: #F5F9FF !important;
    padding-left: 18px !important;
    padding-right: 18px !important;
}
.custompro-file_tabs .ant-card-head{
    padding-left: 0px !important;
}
.custompro-file_tabs .ant-tabs-tab{
    padding: 12px 20px !important;
    padding: 12px 20px !important;
    font-weight: 600;
    font-size: 17px !important;
    color: #aaaaaa !important;
}
/* .ant-modal-body{
    padding-top: 5px !important;
} */
.attendance-employee-card{
    background-color: #00F38D;
    border-radius: 4px;
    padding: 18px;
}
.attendance-logo{
    height: 23px;
}
.attendance-heading{
    color: #FFFFFF;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 17px;
    line-height: 18px;
}
.record-number{
    color: #FFFFFF;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 32px;
}

.Attendence-head{
    color: #666666;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 17px;
}
.attendance-employee-card2{
    background-color: #009EFF;
    border-radius: 4px;
    padding: 18px;
}
.attendance-employee-card3{
    background-color: #FCD354;
    border-radius: 4px;
    padding: 18px;
}
.attendance-employee-card4{
    background-color: #E54545;
    border-radius: 4px;
    padding: 18px;
}

.attendance-employee-card5{
    background-color: #B2B5B8;
    border-radius: 4px;
    padding: 18px;
}

.set-width-card{
    width: 19%;
}

.attendance-employee-car1{
    background-color: #1BDB51;
    border-radius: 4px;
    padding: 18px;
}
.attendance-employee-card6{
    background-color: #035ECA;
    border-radius: 4px;
    padding: 18px;
}
.attendance-employee-card7{
    background-color: #FCD354;
    border-radius: 4px;
    padding: 18px;
}

.attendance-employee-card8{
    background-color: #00D9A3;
    border-radius: 4px;
    padding: 18px;
}
.attendance-employee-card9{
    background-color: #666666;
    border-radius: 4px;
    padding: 18px;
}
.text-area-from{
    height: 10rem !important;
}
.add_Job_bag{
height: 13px;
    color: linear-gradient(90deg, #009EFF -26.47%, #00F38D 127.21%);
}

.down-arrow-dropdown{
    font-size: 17px;
}
.group-images{
    width: 13px;
    margin-right: 7px;
}
.group-images1{
    width: 9px;
    margin-right: 7px;
}
.dropdown-item-text{
    font-family: 'Inter';
font-weight: 400;
font-size: 12px;
color: #666666;
}
.getimages{
    width: 70px;
}
.ziprecruiter{
    width: 100px;

}
.Question_answer_section{
    background-color: #FAFAFA;
    padding: 10px 16px;
    width: 100%;
    height: 151px;
}
.Question_answer_section-modal{
    background-color: #FAFAFA;
    padding: 10px 16px;
    width: 100%;
    height: 147px;
}
.active-Sold{
    background-color:rgba(251, 77, 77, 0.1);
    padding: 4px 20px 4px 20px;
    color: #FB4D4D;
    border-radius: 27px;
    border-radius: 20px;
    font-size: 11px;
    line-height: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
}
.Question_answer_section-multiple{
    background-color: #FAFAFA;
    padding: 10px 16px;
    width: 100%;
    height: 100% !important;
}
.heading-title-level-6{
    color: #00d2e3;
    font-family: 'Inter';
}
.Add-Option{
    color: #009EFF;
    font-size: 11px;
}
.checkbox-section{
    padding: 8px 13px;
    background-color: #FAFAFA;
}
.dropdown_navbar{
    border-radius: 5px !important;
    top: 18px !important;
    right: 40px !important;
}
.small-button-generic{
    font-size: 12px !important;
}
/* media Queries */
@media only screen and (max-width: 1024px) {
    .main-attendance {
      flex-direction: row;
    }
  }
@media only screen and (max-width: 768px) {
    .main-attendance {
      flex-direction: column;
    }
  }

 
  @media only screen and (max-width: 1024px) and (min-width: 768px) {
    .set-width-card {
      width: 48%;
    }
  }
  @media only screen and (max-width: 768px)  {
    .set-width-card {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1024px) and (min-width: 768px) {
    .set-card-top {
        margin-top: 20px;
    }
  }
 
  @media only screen and (max-width: 768px)  {
    .set-card-top {
        margin-top: 20px;
      }
  }

  @media only screen and (max-width: 768px)  {
    .set-card-House {
        margin-top: 20px;
      }
  }