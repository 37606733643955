.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.ant-checkbox-wrapper + .ant-switch {
  display: none;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-checked + .ant-switch {
  display: inline-block;
}
/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}
.react-pdf__Page__annotations.annotationLayer {
  margin-bottom: 20px;
}
.react-pdf__Page__canvas {
  margin: 0 auto;
}
/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}
.jobContainer {
  min-height: calc(100vh - 180px) !important;
}
.vertical-align-middle {
  vertical-align: middle !important;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  float: unset;
  text-align: center;
}
.customSocialList {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  list-style: none;
  padding-left: 0;
}
.customSocialList li {
  font-size: 22px;
}
.customSocialList li:not(:last-child) {
  margin-right: 1rem;
}
.customSocialList li svg {
  vertical-align: middle;
}
.app-header .nav-right {
  margin-left: unset;
}
